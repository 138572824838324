import PropTypes from "prop-types";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Box from "../Box";
import InputWrapper from "../InputWrapper";

const Checkbox = ({ input, label, labelSx, type, ...rest }) => {
  const theme = useContext(ThemeContext);
  return (
    <InputWrapper
      htmlFor={input.name}
      input={input}
      label={label}
      labelSx={{ ml: theme.space[2], order: "2", ...labelSx }}
      {...rest}
    >
      {(props) => (
        <Box as="input" id={input.name} type={type} {...props} {...input} />
      )}
    </InputWrapper>
  );
};

Checkbox.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  labelSx: PropTypes.shape({}),
  type: PropTypes.oneOf(["checkbox", "radio"]),
};

Checkbox.defaultProps = {
  labelSx: {},
  type: "checkbox",
};

export default Checkbox;
