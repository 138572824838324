import PropTypes from "prop-types";
import React from "react";
import Box from "../Box";
import InputWrapper from "../InputWrapper";

const Input = ({ input, ...rest }) => (
  <InputWrapper input={input} {...rest}>
    {({ error, ...inputRest }) => (
      <Box
        as="input"
        // autocomplete="off"
        id={input.name}
        sx={{
          borderColor: error ? "error" : null,
          // boxShadow: error ? '0 0 0 2px rgba(255, 0, 0, .25)' : null,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        variant="inputs.primary"
        width="100%"
        {...inputRest}
        {...input}
      />
    )}
  </InputWrapper>
);

Input.propTypes = {
  input: PropTypes.shape({ error: PropTypes.bool, name: PropTypes.string })
    .isRequired,
};

export default Input;
