import React, { useState, useRef } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import get from "lodash/get";
import { FORM_ERROR } from "final-form";
import Box from "../../base/Box";
import Input from "../../base/Input";
import Textarea from "../../base/TextArea";
import config from "../../config.json";
import FileUploadInput from "../../base/FileUploadInput";
import CheckboxGroup from "../../base/CheckboxGroup";
import stripSpecialCharacters from "../../utilities/strip-special-characters";
import { composeValidations, email, phone, required } from "../../validations";
import Button from "../Button";
import generateS3FileUrl from "../../utilities/generate-s3-file-url";
import GoogleReCaptcha from "../../base/GoogleReCaptcha";

const ContactForm = () => {
  const s3Uploads = [];
  const [isUploading, setIsUploading] = useState(false);
  const [messageStatus, setMessageStatus] = useState(null);
  const colStyled = {
    flex: "none",
    mb: 4,
    pr: [4, null, null, 5],
    width: ["100%", null, null, "calc(100% / 2)"],
  };

  const filePondRef = useRef(null);
  const recaptchaRef = useRef(null);

  const showMessage = (res) => {
    if (res?.status && res.status === 200) {
      setMessageStatus("success");
    } else {
      setMessageStatus("error");
    }

    setTimeout(() => {
      hideMessage();
    }, 10000);
  };

  const hideMessage = () => {
    setMessageStatus(null);
  };

  const clearForm = (formContext) => {
    filePondRef.current.removeFile();
    recaptchaRef.current.reset();
    formContext.form.resetFieldState("fname");
    formContext.form.resetFieldState("lname");
    formContext.form.resetFieldState("phoneNumber");
    formContext.form.resetFieldState("toEmailAddress");
    formContext.form.resetFieldState("companyName");
    formContext.form.resetFieldState("companyWebsite");
    formContext.form.resetFieldState("emailMessage");
    formContext.form.resetFieldState("fileAttachment");
    formContext.form.resetFieldState("fundRaising");
    formContext.form.resetFieldState("fileAttachment");
    formContext.form.resetFieldState("recaptcha");
    formContext.form.reset();
  };

  return (
    <FinalForm
      initialValues={{
        fundRaising: "No",
      }}
      onSubmit={async (values) => {
        const updatedValues = {
          ...values,
          emailMessage: values.emailMessage
            ? values.emailMessage.replace(/\n/g, "<br/>")
            : " ",
          fileAttachment:
            values.fileAttachment && values.fileAttachment.length
              ? generateS3FileUrl(get(values, "fileAttachment[0]", null))
              : null,
        };
        try {
          const response = await fetch(config.emailApi, {
            body: JSON.stringify(updatedValues),
            headers: { "Content-Type": "application/json" },
            method: "POST",
          });
          return { status: response.status };
        } catch (e) {
          return { [FORM_ERROR]: e.message };
        }
      }}
      render={(formContext) => {
        const isDisabled = isUploading || formContext.submitting;
        return (
          <form
            onSubmit={async (event) => {
              const res = await formContext.handleSubmit(event);
              if (res) {
                showMessage(res);
                clearForm(formContext);
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mb: -4,
                mr: [-4, null, null, -5],
              }}
            >
              <Box
                as={Field}
                component={Input}
                label="First Name *"
                name="fname"
                validate={required}
                wrapperSx={colStyled}
              />
              <Box
                as={Field}
                component={Input}
                label="Last Name *"
                name="lname"
                validate={required}
                wrapperSx={colStyled}
              />
              <Box
                as={Field}
                component={Input}
                label="Phone No *"
                name="phoneNumber"
                validate={composeValidations(required, phone)}
                wrapperSx={colStyled}
              />
              <Box
                as={Field}
                component={Input}
                label="Email *"
                name="toEmailAddress"
                validate={composeValidations(required, email)}
                wrapperSx={colStyled}
              />
              <Box
                as={Field}
                component={Input}
                label="Company Name *"
                name="companyName"
                validate={required}
                wrapperSx={colStyled}
              />
              <Box
                as={Field}
                component={Input}
                label="Company Website Link"
                name="companyWebsite"
                // validate={required}
                wrapperSx={colStyled}
              />
              <Box
                as={Field}
                component={Textarea}
                label="Message"
                maxLength="500"
                name="emailMessage"
                rows={5}
                wrapperSx={{ ...colStyled, minWidth: "100%" }}
              />
              <Box
                accept="application/doc, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                acceptedFileTypes={[
                  "application/doc",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  "application/pdf",
                  "application/vnd.ms-powerpoint",
                  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                ]}
                allowRevert
                as={Field}
                bucket={config.buckets.files}
                component={FileUploadInput}
                customPrefix={{ public: "" }}
                fileRenameFunction={({ basename, extension }) => {
                  const timestamp = new Date().getTime();
                  const baseStripped = stripSpecialCharacters(basename);
                  const filename = `${baseStripped}${extension}`;
                  return `${config.stage}/contacts/${timestamp}/${filename}`;
                }}
                fileValidateTypeLabelExpectedTypes="Expects .doc/.docx/.pdf/.ppt/.pptx"
                forwardRef={filePondRef}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      small: {
                        fontSize: ".8rem",
                        fontStyle: "italic",
                        fontWeight: "medium",
                      },
                      span: { fontWeight: "medium", textTransform: "none" },
                    }}
                  >
                    <span>Upload business plan or pitch deck</span>
                    <small>(.pdf, .doc, .docx, .ppt, .pptx)</small>
                  </Box>
                }
                maxFileSize="2MB"
                name="fileAttachment"
                onaddfilestart={() => setIsUploading(true)}
                onRemoveComplete={({ name }) => {
                  s3Uploads.splice(
                    s3Uploads.findIndex((f) => f.s3KeyName === name),
                    1
                  );
                  return formContext.form.change("fileAttachment", [
                    ...get(formContext, "values.fileAttachment", []).filter(
                      (f) => f.s3KeyName !== name
                    ),
                  ]);
                }}
                onUploadComplete={({ fileName }) => {
                  setIsUploading(false);
                  s3Uploads.push({
                    s3BucketName: config.buckets.files,
                    s3KeyName: `${fileName}`,
                  });
                  return formContext.form.change("fileAttachment", s3Uploads);
                }}
                wrapperSx={colStyled}
              />
              <CheckboxGroup
                inline
                innerSx={{ flexDirection: "row" }}
                inputLabelSx={{
                  display: "inline-block",
                  fontWeight: "medium",
                  mb: 3,
                  textTransform: "none",
                }}
                label="Are you actively fundraising?"
                labelSx={{ fontWeight: "medium", mb: 0, mt: 1 }}
                name="fundRaising"
                options={[
                  {
                    label: "Yes",
                    value: "Yes",
                  },
                  {
                    label: "No",
                    value: "No",
                  },
                ]}
                outerSx={colStyled}
              />
              <GoogleReCaptcha
                formContext={formContext}
                forwardRef={recaptchaRef}
                wrapperSx={colStyled}
              />
              <Box
                sx={{
                  ...colStyled,
                  alignItems: "start",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {messageStatus === "success" && (
                  <Box sx={{ color: "success", fontSize: 1, mb: 2 }}>
                    Thank you for contacting us. We will be in touch with you
                    very soon.
                  </Box>
                )}
                {messageStatus === "error" && (
                  <Box sx={{ color: "error", fontSize: 1, mb: 2 }}>
                    Something went wrong. Try again!
                  </Box>
                )}
                <Button
                  disabled={isDisabled}
                  submitting={formContext.submitting}
                  sx={
                    isDisabled ? { opacity: ".5", pointerEvents: "none" } : {}
                  }
                  type="submit"
                  variant="buttons.secondary"
                >
                  {formContext.submitting ? "Sending..." : "Send"}
                </Button>
              </Box>
            </Box>
          </form>
        );
      }}
    />
  );
};

ContactForm.defaultProps = {};

export default ContactForm;
