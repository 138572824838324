import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import { ThemeContext } from "styled-components";
import Box from "../Box";
import Checkbox from "../Checkbox";

const CheckboxGroup = ({
  inline,
  innerSx,
  inputLabelSx,
  inputWrapperSx,
  label,
  labelSx,
  name,
  options,
  outerSx,
  type,
  wrapperSx,
  ...rest
}) => {
  const [focusVal, setFocusVal] = useState(null);
  const theme = useContext(ThemeContext);
  return (
    <Box sx={outerSx}>
      {label && (
        <Box as="label" sx={inputLabelSx} variant="label">
          {label}
        </Box>
      )}
      <Box
        sx={{
          "> *:last-child": {
            mb: 0,
          },
          alignItems: "flex-start",
          display: "flex",
          flexDirection: inline ? "row" : "column",
          // flexWrap: "wrap",
          ...innerSx,
        }}
      >
        {options.map(({ label, value }) => (
          <Box
            key={value}
            onBlur={() => {
              setFocusVal(null);
            }}
            onFocus={() => {
              setFocusVal(value);
            }}
            sx={{
              alignItems: "center",
              display: "flex",
              mb: inline ? "" : theme.space[3],
              mr: inline ? theme.space[4] : "",
            }}
          >
            <Field
              component={Checkbox}
              errorSx={{ width: "100%" }}
              htmlFor={`${name}_${value}`}
              id={`${name}_${value}`}
              inputWrapperSx={inputWrapperSx}
              label={label}
              labelSx={{ textTransform: "none", ...labelSx }}
              name={name}
              type={type}
              value={value}
              wrapperSx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                label: {
                  color: value === focusVal && theme.colors.primary,
                },
                ...wrapperSx,
              }}
              {...rest}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

CheckboxGroup.propTypes = {
  inline: PropTypes.bool,
  innerSx: PropTypes.shape({}),
  inputLabelSx: PropTypes.shape({}),
  inputWrapperSx: PropTypes.shape({}),
  label: PropTypes.string,
  labelSx: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  outerSx: PropTypes.shape({}),
  type: PropTypes.oneOf(["checkbox", "radio"]),
  wrapperSx: PropTypes.shape({}),
};

CheckboxGroup.defaultProps = {
  inline: false,
  innerSx: {},
  inputLabelSx: {},
  inputWrapperSx: {},
  label: null,
  labelSx: {},
  outerSx: {},
  type: "radio",
  wrapperSx: {},
};

export default CheckboxGroup;
