import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const ContactContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allContactJson {
          edges {
            node {
              id
              pageTitle
              heading
              content
              heroBannerSection {
                bannerImage
                heading
                content
                actionButton {
                  buttonLabel
                  buttonPath
                }
              }
              contactTitle
              emailsaddresses {
                mailid
                label
              }
              phonenumbers {
                label
                number
              }
            }
          }
        }
      }
    `}
    render={({ allContactJson: { edges: contactData } }) =>
      children(
        contactData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

ContactContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ContactContainer;
