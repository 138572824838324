import React from "react";
import FileUpload from "../FileUpload";
import InputWrapper from "../InputWrapper";

const FileUploadInput = (props) => {
  return (
    <InputWrapper {...props}>{(rest) => <FileUpload {...rest} />}</InputWrapper>
  );
};

export default FileUploadInput;
