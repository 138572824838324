import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import PropTypes from "prop-types";
import AWS from "aws-sdk";
import React, { PureComponent } from "react";
import { File, FilePond, registerPlugin } from "react-filepond";
import config from "../../config.json";
import Box from "../Box";

AWS.config.update({
  ...config.s3,
  region: config.region,
});

class FileUpload extends PureComponent {
  constructor(props) {
    super(props);

    if (typeof registerPlugin === "function") {
      registerPlugin(FilePondPluginFileRename);
      registerPlugin(FilePondPluginFileValidateSize);
      registerPlugin(FilePondPluginFileValidateType);
    }
  }

  serverLoad = (uniqueFileId, load, error, progress, abort) => {
    const { bucket } = this.props;
    // TODO: update when Storage supports progress events
    // (endlessMode, loadedSize, totalSize)
    // progress(true, 2000, 2000);
    const params = {
      Bucket: bucket,
      Key: uniqueFileId,
    };
    const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
    s3.getObject(params, (err) => {
      if (err) error();
      // an error occurred
      else {
        load();
      }
    });
    return { abort };
  };

  serverProcess = (fieldName, file, metadata, load, error, progress, abort) => {
    const { acl, bucket, onUploadComplete } = this.props;

    const fileName = file.name;
    const contentType = file.type;

    // const progressCallback = ({ lengthComputable, loaded, total }) => progress(lengthComputable || true, loaded, total);

    const params = {
      ACL: acl,
      Body: file,
      Bucket: bucket,
      ContentType: contentType,
      // customPrefix,
      Key: fileName,
      Metadata: metadata,
    };

    const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
    s3.upload(params, (err) => {
      if (err) {
        error();
      } else {
        load(fileName);
        onUploadComplete({ contentType, fileName });
      }
    });

    return { abort };
  };

  serverRevert = (uniqueFileId, load, error) => {
    const { bucket } = this.props;

    const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
    const params = {
      Bucket: bucket,
      Key: uniqueFileId,
    };
    s3.deleteObject(params, (err) => {
      if (err) {
        error();
      } else {
        load();
      }
    });
  };

  serverRemove = (error, { file: { name } }) => {
    const { bucket, onRemoveComplete } = this.props;

    const s3 = new AWS.S3({ apiVersion: "2006-03-01" });
    const params = {
      Bucket: bucket,
      Key: name,
    };
    s3.deleteObject(params, (err) => {
      if (err) {
        error();
      } else {
        onRemoveComplete({ name });
      }
    });
  };

  render() {
    const {
      error,
      existingFiles,
      forwardRef,
      ref,
      maxFileSize,
      fileValidateTypeLabelExpectedTypes,
    } = this.props;

    return (
      <Box
        sx={{
          ".filepond--wrapper": {
            ".filepond--panel-root": {
              bg: "white",
              border: "1px solid",
              borderColor: error ? "error" : "grays.4",
              borderRadius: 2,
              flex: 1,
              fontSize: 1,
            },
            ".filepond--root": {
              m: 0,
            },
          },
        }}
      >
        <FilePond
          {...this.props}
          ref={forwardRef || ref}
          credits={false}
          fileValidateTypeLabelExpectedTypes={
            fileValidateTypeLabelExpectedTypes
          }
          labelFileTypeNotAllowed="Invalid file format"
          maxFileSize={maxFileSize}
          onremovefile={this.serverRemove}
          server={{
            load: this.serverLoad,
            process: this.serverProcess,
            revert: this.serverRevert,
          }}
        >
          {existingFiles.map((f) => (
            <File key={f} origin="local" src={f} />
          ))}
        </FilePond>
      </Box>
    );
  }
}

FileUpload.propTypes = {
  acl: PropTypes.string,
  bucket: PropTypes.string,
  customPrefix: PropTypes.shape({
    private: PropTypes.string,
    protected: PropTypes.string,
    public: PropTypes.string,
  }),
  error: PropTypes.bool,
  existingFiles: PropTypes.arrayOf(PropTypes.string),
  fileValidateTypeLabelExpectedTypes: PropTypes.string,
  forwardRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  identityId: PropTypes.string,
  level: PropTypes.string,
  maxFileSize: PropTypes.string,
  onRemoveComplete: PropTypes.func,
  onUploadComplete: PropTypes.func,
  ref: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
};

FileUpload.defaultProps = {
  acl: null,
  bucket: null,
  customPrefix: { private: "", protected: "", public: "" },
  error: false,
  existingFiles: [],
  fileValidateTypeLabelExpectedTypes: "",
  forwardRef: null,
  identityId: null,
  level: "public-read",
  maxFileSize: "2MB",
  onRemoveComplete: () => {},
  onUploadComplete: () => {},
  ref: null,
};

export default FileUpload;
