import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import Box from "../Box";
import { trim } from "../../utilities/normalizers";
import { required } from "../../validations";
import config from "../../config.json";
import Input from "../Input";

const GoogleReCaptcha = ({
  formContext,
  forwardRef,
  ref,
  wrapperSx,
  ...rest
}) => {
  const handleChangeReCaptcha = (value) => {
    formContext.form.change("recaptcha", value);
  };

  return config.googleReCaptchaSiteKey ? (
    <Box sx={wrapperSx}>
      <ReCAPTCHA
        ref={forwardRef || ref}
        onChange={handleChangeReCaptcha}
        sitekey={config.googleReCaptchaSiteKey}
        size="normal"
        {...rest}
      />
      <Box
        as={Field}
        component={Input}
        name="recaptcha"
        normalize={trim}
        type="hidden"
        validate={required}
      />
    </Box>
  ) : null;
};

GoogleReCaptcha.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
  }).isRequired,
  forwardRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  ref: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  wrapperSx: PropTypes.shape({}),
};

GoogleReCaptcha.defaultProps = {
  forwardRef: null,
  ref: null,
  wrapperSx: {},
};

export default GoogleReCaptcha;
