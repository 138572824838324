import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import SEO from "../SEO/seo";
import ContactForm from "../ContactForm";
import ContactContainer from "../../containers/ContactContainer";
import Section from "../Section";
import PageHeroBanner from "../PageHeroBanner";
import MarkdownToHtml from "../MarkdownToHtml";

const ContactPage = () => (
  <ContactContainer>
    {(contactData) => {
      const pageName = "contact";
      const contactInfo = contactData
        .map((ele) => (ele.id === pageName ? ele : null))
        .find((el) => el);
      const { heroBannerSection, contactTitle, heading, content } = contactInfo;
      const theme = useContext(ThemeContext);

      return (
        <>
          <SEO pathname="contact" templateTitle={contactInfo.pageTitle} />
          {heroBannerSection && (
            <PageHeroBanner
              actionButton={heroBannerSection.actionButton}
              bannerImage={heroBannerSection.bannerImage}
              content={{
                heading: heroBannerSection.heading,
                text: heroBannerSection.content,
              }}
            />
          )}
          <Section>
            <Box
              sx={{
                mb: [2, null, null, 5],
                textAlign: ["center", null, null, "left"],
              }}
              variant="markdown.h2"
            >
              Contact us
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: ["column", null, null, "row"],
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  bg: "blueLight",
                  display: "flex",
                  height: "auto",
                  my: [0, null, null, 5],
                  p: 4,
                  textAlign: ["center", null, null, "left"],
                  width: ["100%", null, null, "32rem"],
                  zIndex: 1,
                }}
              >
                <MarkdownToHtml
                  content={content}
                  contentSx={{
                    lineHeight: 3,
                    p: { color: "white", fontSize: 1 },
                  }}
                  heading={heading}
                  headingSx={{
                    h3: {
                      color: "white",
                      fontSize: 2,
                      fontWeight: "bold",
                      textTransform: "none",
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  ...theme.designElement.rightBottom,
                  bg: "grayBackground",
                  display: "flex",
                  flexDirection: "column",
                  ml: [null, null, null, -6],
                  pl: [4, null, null, "13rem"],
                  pr: [4, null, null, 5],
                  py: 5,
                  width: ["100%", null, null, "calc(100% - 13rem)"],
                }}
              >
                <Box variant="markdown.h3">{contactTitle}</Box>
                <ContactForm />
              </Box>
            </Box>
          </Section>
        </>
      );
    }}
  </ContactContainer>
);

export default ContactPage;
